.third-slide-container {
  margin-left: 150px; /* 왼쪽 네비게이션 바로부터 100px */
  width: calc(100% - 200px); /* 오른쪽 여백을 고려하여 너비 조정 */
  height: calc(100%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px; /* 모서리 둥글게 */
  overflow: hidden;
 
}
.swiper-pagination {
  margin-top: -2px; /* 원하는 마진 상단 값 설정 */
  position: absolute;
  z-index: 4;
}
.project-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.project-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2개의 열 */
  grid-template-rows: repeat(2, 1fr); /* 2개의 행 */
  padding-left: 1rem;
  margin-left: 3rem;
  gap: 20px;
  max-width: 90vw;
  max-height: 90vw
}
.outer-swiper .swiper-button-next,
.outer-swiper .swiper-button-prev {
 
  margin: -7px; 
}


.project-content-list {

  text-align: left;
  font-size: 15px;
  font-weight: 200;

}
.project-content-list li{
  margin-bottom: 5px;
}
.project-card {
  display: flex;
  flex-direction: row; /* 카드를 행 방향으로 설정 */
  min-height: 300px;
  box-shadow: 0 2px 2px;
  border-radius: 10px;
  overflow: hidden;
  height: auto; /* 높이 자동 조정 */
  max-width: 90%;
  max-height: 90%;
  margin: 0; 
  box-shadow: 5px 5px 15px rgba(0,0,0,0.2), /* 책의 깊이 효과 */
             inset 0 -1px 5px rgba(0,0,0,0.1); /* 페이지 내부 그림자 */
             overflow: hidden;
             
}
.project-image-container {
  flex-basis: 20%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px; /* 이미지 주위에 패딩 추가 */

  border-left: 10px; /* 모서리 둥글게 처리 */
}

@media screen and (max-width: 1366px) {
  .third-slide-container {
    margin-left: 100px;
    width: calc(100% - 150px);
    height: auto; /* 필요에 따라 높이를 조정 */
  }

  .project-card {
    max-width: 650px; /* 카드의 최대 너비 조정 */
    max-height: 650px;
  }
}


/* 추가적으로 모바일 환경이나 더 작은 화면 크기에 대한 스타일을 정의할 수 있습니다. */
@media screen and (max-width: 768px) {
  .third-slide-container {
    margin-left: 50px;
    width: calc(100% - 100px);
    height: auto; /* 더 작은 화면에서 높이 조정 */
  }

  .project-card {
    max-width: 100%; /* 카드 너비를 화면 너비에 맞춤 */
  }
}
.project-image {
  flex-grow: 1; /* 이미지가 컨테이너를 꽉 채우도록 */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(241, 252, 254);
  width: 100%; /* 컨테이너 너비 고정 */
  height: 50%; /* 컨테이너 높이 고정 */
  border-radius: 20px;
  overflow: hidden; /* 이미지가 컨테이너를 넘어가지 않도록 설정 */
}

.project-image img {
  max-width: 70%; /* 이미지 최대 너비 100% */
  max-height: 70%; /* 이미지 최대 높이 100% */
  object-fit: contain; /* 이미지 비율 유지 */
}
.project-info {
  flex-basis: 60%; /* 정보 섹션 너비 */
  padding: 20px; /* 패딩 설정 */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* 내용물 정렬 */
  border-left: 2px solid #bdbdb5/* 세련된 구분선 추가 */
}

.project-details {
  font-size: 15px; /* 글자 크기 */
  color: #333; /* 글자 색상 */
  
}



.project-details ul {
  padding: 0;
  list-style: none; /* 리스트 스타일 제거 */
  display: flex; /* 플렉스박스로 표시 */
  flex-wrap: wrap; /* 여러 줄로 나누어 표시 */
}

.project-details li {
  background: #def; /* 배경색 */
  margin-right: 5px; /* 오른쪽 마진 */
  margin-bottom: 5px; /* 아래쪽 마진 */
  padding: 5px 10px; /* 패딩 */
  border-radius: 5px; /* 모서리 둥글게 */
  font-size: 12px; /* 글자 크기 */
}


.project-links a {

  text-decoration: none; /* 링크 밑줄 제거 */
  color: #007bff; /* 링크 색상 */
  font-weight: bold; /* 글자 굵기 */
  display: inline-flex; /* 아이콘과 텍스트 정렬을 위해 변경 */
  align-items: center; /* 세로 중앙 정렬 */
  justify-content: center; /* 가로 중앙 정렬 */
}

.project-links svg {
  width: 24px; /* 아이콘 크기 */
  height: 24px; /* 아이콘 크기 */
  color: #333; /* 아이콘 색상 */
  margin-top: 10px
}
.project-stack {
  display: flex; /* 플렉스박스로 표시 */
  flex-wrap: wrap; /* 여러 줄로 나누어 표시 */
  gap: 5px; /* 태그 사이의 간격 */
  font-weight: 600;
}

.project-stack li {
  padding: 1px;
  border-radius: 5px; /* 모서리 둥글게 */

  list-style: none; /* 리스트 스타일 제거 */
  margin-bottom: 2px; /* 아래쪽 마진 */
  white-space: nowrap; /* 줄바꿈 없이 한 줄로 표시 */
}
.reon-image img {
  max-width: 70%; /* 기존보다 큰 비율로 조정 */
  max-height: 70%; /* 기존보다 큰 비율로 조정 */
}