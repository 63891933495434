.last-container {
    text-align: center;
    padding: 40px;
    background-color: #f5f5f5; /* 배경색 추가 */
    border-radius: 10px; /* 테두리 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 그림자 추가 */
    max-width: 700px; /* 최대 너비 설정 */
    margin-left: 30%;
   margin-top: 10%;
  }
  
  .last-container h2 {
    margin-bottom: 30px;
    color: #333; /* 제목 색상 */
    font-size: 2em; /* 큰 제목 */
  }
  
  .last-container form {
    margin-bottom: 30px;
  }
  
  .last-container input,
  .last-container textarea {
    width: 90%; /* 너비 조정 */
    padding: 12px;
    margin-bottom: 15px;
    border: 2px solid #ccc; /* 테두리 두껍게 */
    border-radius: 8px; /* 테두리 둥글게 */
    font-size: 1em; /* 글자 크기 */
  }
  
  .last-container button {
    padding: 12px 25px;
    border: none;
    background-color: #bdbdb5; /* 버튼 색상 변경 */
    color: white;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1em; /* 글자 크기 */
    transition: background-color 0.3s; /* 부드러운 색상 변경 효과 */
  }
  
  .last-container button:hover {
    background-color: #bdbdb5; /* 호버 상태 색상 변경 */
  }
  
  .last-container .social-links {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    
  }
  
  .last-container .social-links a {
    margin: 0 15px;
    color: #333; /* 아이콘 색상 */
    font-size: 36px;
  }
  
  /* 반응형 디자인 */
  @media screen and (max-width: 768px) {
    .last-container {
      padding: 20px;
      max-width: 100%;
    }
  }
  