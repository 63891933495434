.title {
    color: #ffffff; /* 밝은 색상 */
    background: linear-gradient(45deg, #e6e6e6, #ffffff); 
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3vw;
    text-shadow: 2px 12px 12px rgba(233, 232, 232, 0.9);

}
.bor{
 
  margin-top: 25vw; 
  
}
.content{
    color: #f8f8f8; /* 밝은 색상 */
    background: linear-gradient(45deg, #e6e6e6, #b5b4b4); 
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3vw;
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.8); 
}

@keyframes pulse {
    0% {
        transform: scale(1);
       
    }
    50% {
        transform: scale(1.05);
        
    }
    100% {
        transform: scale(1);
      
    }
}

.view-work-button {
  padding: 10px;
  background-color: black;  
  color: white;  /* 텍스트 색상 검정 */
  border: 2px solid #000;  /* 검정색 테두리 */
  border-radius: 10px;  /* 테두리 둥글게 */
  cursor: pointer;
  font-size: 1vw;
  transition: background-color 0.3s ease, color 0.3s ease;  
  animation: pulse 2.5s infinite;
  z-index: 10 !important; 
 
  
}

  
  .view-work-button:hover {
    
    background-color: #000;  /* 호버 시 배경색 검정 */
    color: white;
  }
  @keyframes bounceDown {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(30px);
    }
    60% {
      transform: translateY(10px);
    }
  }
  
  .scroll-down {
    margin-top: 20px;
    color: #fff;
    font-size: 24px;
    animation: bounceDown 1.5s infinite;
    text-align: center;
    
  }
  

  body {
    background-color: white;
    color: black;
    /* ... */
  }
  
  /* Dark theme styles */
  body.dark-theme {
    background-color: #333;
    color: white;
    /* ... */
  }
  


  .bubble-container {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -2; /* 다른 요소들 뒤에 배치 */
  }
  
  .bubble {
    width: 20px;
    height: 20px;
    background-color: rgba(79, 193, 233, 0.5); /* 청량한 푸른색 */
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.6); /* 비눗방울의 테두리에 하이라이트 효과 추가 */
    animation: rise 8s infinite ease-in;
  }
  
  @keyframes rise {
    0% {
      bottom: 0;
      transform: scale(0.1); /* 더 작게 시작 */
      opacity: 0.2;
    }
    25% {
      background-color: rgba(79, 233, 193, 0.6); /* 색상 변화 추가 */
    }
    50% {
      transform: scale(1); /* 더 커지도록 설정 */
      opacity: 0.8;
    }
    75% {
      background-color: rgba(79, 193, 233, 0.3); /* 또 다른 색상 변화 추가 */
    }
    100% {
      bottom: 100%;
      transform: scale(0.1); /* 다시 작아지도록 설정 */
      opacity: 0;
    }
  }
  