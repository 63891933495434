.testContainer {
    width: 100%;  
    height: 100%;
    /* background: linear-gradient(to top left, #f2ecda, #ADD8E6);  */
 
    -webkit-font-smoothing: antialiased;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-35%);
    z-index: 3;
}

.testTitle {
    height: 8vw;
}

.testTitleSpan {
    position: relative;
    top: 1vw;
    display: inline-block;
    animation: bounce .3s ease infinite alternate;
    font-family: 'Titan One', cursive;
    font-size: 8vw;
    color: #FFF;
    text-shadow: 0 1px 0 #CCC,
                 0 2px 0 #CCC,
                 0 3px 0 #CCC,
                 0 4px 0 #CCC,
                 0 5px 0 #CCC,
                 0 6px 0 transparent,
                 0 7px 0 transparent,
                 0 8px 0 transparent,
                 0 9px 0 transparent,
                 0 10px 10px rgba(0, 0, 0, .6);
}

.testTitleSpan:nth-child(2) { animation-delay: .1s; }
.testTitleSpan:nth-child(3) { animation-delay: .2s; }
.testTitleSpan:nth-child(4) { animation-delay: .3s; }
.testTitleSpan:nth-child(5) { animation-delay: .4s; }
.testTitleSpan:nth-child(6) { animation-delay: .5s; }
.testTitleSpan:nth-child(7) { animation-delay: .6s; }
.testTitleSpan:nth-child(8) { animation-delay: .7s; }
.testTitleSpan:nth-child(9) { animation-delay: .8s; }
.testTitleSpan:nth-child(10) { animation-delay: .9s; }
.testTitleSpan:nth-child(11) { animation-delay: .10s; }
.testTitleSpan:nth-child(12) { animation-delay: .11s; }
.testTitleSpan:nth-child(13) { animation-delay: .12s; }

@keyframes bounce {
    100% {
        top: -20px;
        text-shadow: 0 1px 0 #CCC,
                     0 2px 0 #CCC,
                     0 3px 0 #CCC,
                     0 4px 0 #CCC,
                     0 5px 0 #CCC,
                     0 6px 0 #CCC,
                     0 7px 0 #CCC,
                     0 8px 0 #CCC,
                     0 9px 0 #CCC,
                     0 50px 25px rgba(0, 0, 0, .4);
    }
}



