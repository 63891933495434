.content {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    color: black;
    z-index: 5;
  }
  
  .content img {
    width: 100%;
  }
  
  .curtainContainer {
    position: absolute;
    display: flex;
    top: -10%;
    width: 100%;
    height: 110%;
    margin: auto;
    transform-style: preserve-3d;
    overflow: hidden;
    transition: transform 5s ease;
    /* z-index: 20; */
  }
  
  .curtainContainer:nth-child(1) {
    transform-origin: -120% top;
    justify-content: flex-start;
    transform: translatex(-3vw);
  }
  
  .curtainContainer:nth-child(2) {
    transform-origin: 120% top;
    justify-content: flex-end;
    transform: translatex(5vw);
  }
  
  .rollLeft {
    transform: translatex(-150vw) scaleX(0);
  }
  
  .rollRight {
    transform: translatex(150vw) scaleX(0);
  }
  
  .curtainBody:hover .curtainContainer:nth-child(1) {
    transform: translatex(-150vw) scaleX(0);
  }
  
  .curtainBody:hover .curtainContainer:nth-child(2) {
    transform: translatex(150vw) scaleX(0);
  }
 
  .unCurtain {
    display: inline-block;
    width: 15vw;
    margin: 0;
    padding: 0;
    height: 120vh;
    background-size: 100% 100%;
    background: repeating-linear-gradient(to left, #8a8a8a 4vw, #a3a3a3 12vw, #7c7c80 15vw);
    transform-origin: 0 0%;
    transform: rotate(3deg);
    transition: transform 3s ease;
    animation: swing 2s ease infinite;
  }
 
  @keyframes swing {
    50% {
      transform: rotate(-3deg);
    }
  }
  .unCurtain:nth-child(2) {
    margin-left: -10vw;
    animation-delay: -0.1s;
  }
  
  .unCurtain:nth-child(3) {
    margin-left: -10vw;
    animation-delay: -0.2s;
  }
  
  .unCurtain:nth-child(4) {
    margin-left: -10vw;
    animation-delay: -0.3s;
  }
  
  .unCurtain:nth-child(5) {
    margin-left: -10vw;
    animation-delay: -0.4s;
  }
  
  .unCurtain:nth-child(6) {
    margin-left: -10vw;
    animation-delay: -0.5s;
  }
  
  .unCurtain:nth-child(7) {
    margin-left: -10vw;
    animation-delay: -0.6s;
  }
  
  .unCurtain:nth-child(8) {
    margin-left: -10vw;
    animation-delay: -0.7s;
  }
  
  .unCurtain:nth-child(9) {
    margin-left: -10vw;
    animation-delay: -0.8s;
  }
  
  .unCurtain:nth-child(10) {
    margin-left: -10vw;
    animation-delay: -0.9s;
  }
  
  .unCurtain:nth-child(11) {
    margin-left: -10vw;
    animation-delay: -1s;
  }
 
  .unCurtain:nth-child(12) {
    width: 10vw;
    background: repeating-linear-gradient(to left, #8a8a8a 4vw, #a3a3a3 8vw, #7c7c80 10vw);
    margin-left: -10vw;
    animation-delay: -1.1s;
  }
  
  .curtainBody {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  .overlay {
    display: block;
    position: absolute;
    color: white;
    top: 0%;
    left: 0%;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(to top, rgba(2, 2, 2, 0) 0vh, black 140vh);
    transition: opacity 3s ease;
    z-index: 4;
  }
  
  .curtainBody:hover .overlay {
    opacity: 0;
  }