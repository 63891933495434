.navbar {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #bdbdb5; /* 밝은 배경색 */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* 부드러운 그림자 효과 */
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 2;
  transform: translateX(-100%); /* Start off-screen */
  transition: transform 0.5s ease-in-out;
}

.navbar.visible {
  transform: translateX(0); /* Move into view */
  transition: transform 0.7s ease-in-out; /* Faster transition */
}

.nav-item {
  padding: 12px 24px;
  border: none;
  background-color: transparent;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 1.1rem; /* 크기 조정 */
  border-radius: 8px; /* 둥근 모서리 */
}

.nav-item:hover, .nav-item.active {
 color: #f5f5dc;
}

.theme-toggle {

  cursor: pointer;
  font-size: 1.1rem; /* 아이콘 크기 조정 */
  
}

.theme-toggle.sun {
  color: gold; /* 해 아이콘 색상 */
}

.theme-toggle.moon {
  color: #2c2c2c; /* 달 아이콘 색상 */
}
