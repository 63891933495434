.second-slide-container {
    display: flex;
    height: 90vh;
    width: 90vw;
    margin-left: auto;
    padding: 2vw;
    border-radius: 10px;  
   
}

/* 프로필 섹션 스타일 */
/* 프로필 섹션 스타일 */
.profile-section {
 
  border-radius: 10px;
  flex: 1;
  padding: 20px;
  box-shadow: 5px 5px 15px rgba(0,0,0,0.2), /* 책의 깊이 효과 */
  inset 0 -1px 5px rgba(0,0,0,0.1); /* 페이지 내부 그림자 */
}


/* 스킬 섹션 스타일 */
.skills-section {
  border-radius: 10px;
  flex: 1;
  padding: 20px;
  text-align: left;
  margin-left: 5px;
  box-shadow: 5px 5px 15px rgba(0,0,0,0.2), /* 책의 깊이 효과 */
  inset 0 -1px 5px rgba(0,0,0,0.1); /* 페이지 내부 그림자 */
  overflow: hidden;
  align-items: stretch;
}

.animate-profile {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 2.5s, opacity 2.5s;
  
}

.animate-skills {
  transform: translateX(100%);
  opacity: 0;
  transition: transform 2.5s, opacity 2.5s;
}

/* 애니메이션 활성화 상태 */
.active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 1.5s, opacity 1s;
}

.profile-image {
    width: 80%;
    height: 80%;
    max-width: 300px;
    max-height: 300px; 
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    object-fit: cover;
    align-self: center;
   
}


.skill-category h3 {
    color: skyblue;
    font-size: 1vw; /* Slightly larger for emphasis */
}

.career-awards {

  max-height: calc(90vh - 50px);
}


  .career-awards p {
    font-size:0.8vw;
  }
  .career-awards h6 {
    color: skyblue;
    margin: 0;
    font-size: 1vw 
    
  }
  
  .career-awards ul {
    list-style-type: none; /* Removes default list styling */
    font-size: 0.8vw;
  }
  
  .career-awards li {
    
    font-size: 0.8vw; /* Slightly smaller font size for list items */
  }
  